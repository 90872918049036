import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarLink,
  SidebarMenu,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            QUIÉNES SOMOS
          </SidebarLink>
          <SidebarLink to="/servicios" onClick={toggle}>
            SERVICIOS
          </SidebarLink>
          <SidebarLink to="/principios" onClick={toggle}>
            PRINCIPIOS
          </SidebarLink>
          <SidebarLink to="/solicitud_de_servicios" onClick={toggle}>
            SOLICITUD DE SERVICIOS
          </SidebarLink>
          <SidebarLink to="/recursos" onClick={toggle}>
            RECURSOS
          </SidebarLink>
          <SidebarLink to="/contacto" onClick={toggle}>
            CONTACTO
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
