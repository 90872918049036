import React, { useState, useEffect } from 'react';

import { LineDot } from '../molecules/LineDot';

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { ConnectionComponent, baseUrl } from '../../utils/connection.js';

import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { useLocation } from "react-router-dom";

import SelectElement from '../atoms/SelectElement';
import { DisclaimerContainer, DividerDiv, FlexCenter, ResourcesContainer, SearchContainer, SelectGridContainer, SubContainerD } from '../atoms/containers';
import { CarouselWrapper, DescriptionCenter, DetailsWrapper, DisclaimerWrapper, DotWrapper, FooterWrapper, GapCard, HeaderCard, HyphenSmall, IconWrapperFooter, InfoWrapperA, ResourcesDescription, ResourcesWrapper, ServiceTitleCard, TitleCard } from '../atoms/wrappers';
import { BtnCarousel, ButtonLink, SearchButton } from '../atoms/buttons';
import { ServicesH1, ServicesWhite, ServicesWhiteA } from '../atoms/h1';
import { CarouselImg } from '../atoms/img';
import { PromoteBar, PromoteBarA, ServicesPageBar, ServicesPageBarA } from '../atoms/bars';
import { Descripciones, ServicesPB, selectP } from '../atoms/p';
import { ServicesLi, ServicesUl } from '../atoms/ul';
import { ResourcesCard, ResourcesCards } from '../atoms/cards';
import { LineDivider } from '../atoms/hr';
import { IconCard } from '../atoms/icons';
import { FaShareAlt } from "react-icons/fa";

import '../atoms/styles.css';
import { SearchInput } from '../atoms/forms';

const ResourcesPage = () => {
    const MySwal = withReactContent(Swal);

    const carousel = ConnectionComponent('Carousel?sort=orden');
    const recursos = ConnectionComponent('Recursos');

    const [implementadora, setImplementadora] = useState('');
    const [servicio, setServicio] = useState('');
    const [recurso, setRecurso] = useState('');

    const [query, setQuery] = useState('');
    const [queryText, setQueryText] = useState('');

    let recursosElementos = ConnectionComponent(query === '' ? `Recurso?filter[status][_eq]=published&fields=*.*.*${recurso}${servicio}${implementadora}` : query);

    const tipoRecursos = ConnectionComponent('Tipo_de_recurso');
    const servicios = ConnectionComponent('Servicios');
    const implementadoras = ConnectionComponent('Implementadoras');

    const services_opts = Array.isArray(servicios) ? servicios.map((service) => {
        return { 'value': service.id, 'name': service.Titulo.charAt(0).toUpperCase() + service.Titulo.slice(1).toLowerCase() }
    }) : {};

    const recursos_opts = Array.isArray(tipoRecursos) ? tipoRecursos.map((recurso) => {
        return { 'value': recurso.id, 'name': recurso.titulo, 'icono': recurso.icono }
    }) : {};

    const implementadora_opts = Array.isArray(implementadoras) ? implementadoras.map((implementadora) => {
        return { 'value': implementadora.id, 'name': implementadora.nombre, 'color': implementadora.color }
    }) : {};

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }

    let toastMixin = MySwal.mixin({
        toast: true,
        icon: 'success',
        title: 'General Title',
        animation: false,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    function shareUrl(uuid) {
        navigator.clipboard.writeText(`https://${window.location.hostname}/recursos?open=${uuid}`)

        toastMixin.fire({
            animation: true,
            title: 'Copiado al portapapeles'
        });
    }

    const handleQueryText = () => {
        if (queryText || queryText !== '') {
            setQuery(`Recurso?filter={"_or":[{"servicio":{"Servicios_id":{"Titulo":{"_contains":"${queryText}"}}}},{"titulo":{"_contains":"${queryText}"}}]}&fields=*.*.*`);
        } else {
            setQuery('');
        }
    }

    const search = useLocation().search;
    const searchUUID = new URLSearchParams(search).get('open');
    const retrieveResource = searchUUID ? ConnectionComponent(`Recurso?filter[status][_eq]=published&fields=*.*.*&filter[uuid][_eq]=${searchUUID}`) : null;

    useEffect(() => {
        if (searchUUID && retrieveResource.length > 0) {
            MySwal.fire({
                showCloseButton: true,
                customClass: {
                    htmlContainer: 'htmlContainer',
                    popup: 'popup',
                    footer: 'footerText'
                },
                showConfirmButton: false,
                popup: 'bezel',
                footer: <FooterWrapper style={{ backgroundColor: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'] }}></FooterWrapper>,
                html:
                    <div>
                        <div>
                            <HeaderCard
                                style={{ color: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'] }}>
                                <IconCard style={{ backgroundColor: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'], WebkitMaskImage: `url(${baseUrl}/assets/${retrieveResource[0]['tipo'][0]['Tipo_de_recurso_id']['icono']})`, maskImage: `url(${baseUrl}/assets/${retrieveResource[0]['tipo'][0]['Tipo_de_recurso_id']['icono']})` }} />
                                <span style={{ fontWeight: '500', color: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'] }}>{retrieveResource[0]['tipo'][0]['Tipo_de_recurso_id']['titulo']}</span>
                            </HeaderCard>
                            <GapCard />
                            <TitleCard>
                                <h2 style={{ fontWeight: '500' }}>{retrieveResource[0].titulo}</h2>
                            </TitleCard>
                            <ServiceTitleCard>{capitalizeFirstLetter(retrieveResource[0]['servicio'][0]['Servicios_id']['Titulo'])}</ServiceTitleCard>
                        </div>
                        <div>
                            {retrieveResource[0]['multimedia'] && retrieveResource[0]['multimedia']['id'] &&
                                <img src={`${baseUrl}/assets/${retrieveResource[0]['multimedia']['id']}`} alt="s-logo" style={{ width: '100%' }} />
                            }
                        </div>
                        <div>
                            <div style={{ minHeight: '15rem' }}>
                                <div>
                                    <DescriptionCenter>
                                        <HyphenSmall>
                                            <ReactMarkdown
                                                components={{
                                                    // Use `CustomLink` instead of anchor element `a`
                                                    p: selectP,
                                                }}
                                            >
                                                {retrieveResource[0]['descripcion_markdown']}
                                            </ReactMarkdown>
                                            <div style={{ textAlign: 'center', alignItems: 'center', marginTop: '3rem', display: 'grid', justifyContent: 'center' }}>
                                                <ButtonLink style={{ backgroundColor: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} href={retrieveResource[0]['enlace_boton']} target="_blank" rel="noreferrer">{retrieveResource[0]['texto_boton']}</ButtonLink>
                                                {retrieveResource[0]['botones'].length > 0 &&
                                                    retrieveResource[0]['botones'].filter((boton) => boton['item']['visible'] === true).map(boton =>
                                                        <ButtonLink style={{ backgroundColor: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} href={boton['item']['enlace'] ? boton['item']['enlace'] : `https://admin.iniciativaecos.com/assets/${boton['item']['archivo']}`} target="_blank" rel="noreferrer">{boton['item']['texto_boton']}</ButtonLink>
                                                    )
                                                }
                                                <ButtonLink as="button" style={{ backgroundColor: retrieveResource[0]['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} onClick={() => shareUrl(retrieveResource[0]['uuid'])}><FaShareAlt style={{ marginRight: '6px' }} /><span style={{ position: 'relative', bottom: '2px' }}>Compartir recurso</span></ButtonLink>
                                            </div>
                                        </HyphenSmall>
                                    </DescriptionCenter>
                                </div>
                            </div>
                        </div>
                    </div>
            })
        }
    }, [searchUUID, retrieveResource, MySwal]);

    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        backgroundColor: 'transparent',
        border: 0,
        width: 30,
        height: 30,
        cursor: 'pointer',
    };

    return (
        <ResourcesContainer>
            <SubContainerD>
                <ResourcesWrapper>
                    <Carousel
                        autoPlay
                        showThumbs={false}
                        showStatus={false}
                        showArrows={true}
                        infiniteLoop={true}
                        emulateTouch={true}
                        useKeyboardArrows={true}
                        renderArrowPrev={(onClickHandler, hasPrev, label) =>
                            hasPrev && (
                                <button type="button" className='left' onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: '3rem' }}>
                                    <span className="arrow first next "></span>
                                    <span className="arrow second next "></span>
                                </button>
                            )
                        }
                        renderArrowNext={(onClickHandler, hasNext, label) =>
                            hasNext && (
                                <button type="button" className='right' onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: '3rem' }}>
                                    <span className="arrow first next "></span>
                                    <span className="arrow second next "></span>
                                </button>
                            )
                        }
                    >
                        {carousel.length > 0 ?
                            carousel.map(image =>
                                <div key={`div-${image.id}`} onClick={() => {
                                    return !image['texto_boton'] ? window.open(image.enlace, "_blank") : null;
                                }}>
                                    <CarouselWrapper>
                                        <ServicesWhiteA>{image.titulo}</ServicesWhiteA>
                                        {image['texto_boton'] ? <BtnCarousel href={image.enlace} target="_blank" rel="noreferrer">{image['texto_boton']}</BtnCarousel> : null}
                                    </CarouselWrapper>
                                    <CarouselImg alt={`carrousel-${image.id}`} src={`${baseUrl}/assets/${image['imagen']}`} />
                                </div>
                            )
                            : <></>}
                    </Carousel>
                </ResourcesWrapper>
                <InfoWrapperA>
                    <ResourcesDescription>
                        <div>
                            <ServicesH1>{recursos.length > 0 ? recursos[0]['titulo'] : 'Recursos'}</ServicesH1>
                            <PromoteBar />
                            <LineDot margin='3rem' />
                        </div>
                        <div style={{ marginBottom: '3rem' }}>
                            {recursos.length > 0 ?
                                <ReactMarkdown
                                    components={{
                                        // Use `CustomLink` instead of anchor element `a`
                                        p: Descripciones,
                                    }}
                                >
                                    {recursos[0]['descripciones']}
                                </ReactMarkdown>
                                : ''}
                        </div>
                    </ResourcesDescription>
                </InfoWrapperA>
            </SubContainerD>
            <FlexCenter>
                <div>
                    <ServicesH1 >{recursos.length > 0 ? recursos[0]['encabezado_busqueda'] : 'Explora con nosotros'}</ServicesH1>
                    <PromoteBarA />
                </div>
            </FlexCenter>
            <FlexCenter>
                <SelectGridContainer>
                    <div>
                        <SelectElement
                            setter={setRecurso}
                            setterCondition="[tipo][Tipo_de_recurso_id][id]"
                            setterQuery={setQuery}
                            defaultText="Tipo de recurso"
                            optionsList={recursos_opts} />
                    </div>
                    <div>
                        <SelectElement
                            setter={setServicio}
                            setterCodition='[servicio][Servicios_id][id]'
                            setterQuery={setQuery}
                            defaultText="Tipo de servicio"
                            optionsList={services_opts} />
                    </div>
                    <div>
                        <SelectElement
                            setter={setImplementadora}
                            setterCondition='[implementadora][Implementadoras_id][id]'
                            setterQuery={setQuery}
                            defaultText="Implementadora"
                            optionsList={implementadora_opts} />
                    </div>
                </SelectGridContainer>
            </FlexCenter>
            <DividerDiv>
                <LineDivider />
            </DividerDiv>
            <DividerDiv>
                <SearchContainer>
                    <div>
                        <SearchInput onChange={(e) => setQueryText(e.target.value)} placeholder={recursos.length > 0 ? recursos[0]['texto_busqueda'] : 'Buscar'} />
                    </div>
                    <div>
                        <SearchButton onClick={() => handleQueryText()}>{recursos.length > 0 ? <span><FontAwesomeIcon icon={faMagnifyingGlass} /> {recursos[0]['boton_busqueda']} </span> : 'Explorar'}</SearchButton>
                    </div>
                </SearchContainer>
            </DividerDiv>
            <DividerDiv>
                <LineDivider />
            </DividerDiv>
            <DotWrapper>
                <LineDot margin='3rem' />
            </DotWrapper>
            <ResourcesCards>
                {recursosElementos.length > 0 ?
                    recursosElementos.map(recurso =>
                        <ResourcesCard key={`recurso${recurso.id}`} onClick={() => {
                            MySwal.fire({
                                showCloseButton: true,
                                customClass: {
                                    htmlContainer: 'htmlContainer',
                                    popup: 'popup',
                                    footer: 'footerText'
                                },
                                showConfirmButton: false,
                                popup: 'bezel',
                                footer: <FooterWrapper style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'] }}></FooterWrapper>,
                                html:
                                    <div>
                                        <div>
                                            <HeaderCard
                                                style={{ color: recurso['implementadora'][0]['Implementadoras_id']['color'] }}>
                                                <IconCard style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'], WebkitMaskImage: `url(${baseUrl}/assets/${recurso['tipo'][0]['Tipo_de_recurso_id']['icono']})`, maskImage: `url(${baseUrl}/assets/${recurso['tipo'][0]['Tipo_de_recurso_id']['icono']})` }} />
                                                <span style={{ fontWeight: '500', color: recurso['implementadora'][0]['Implementadoras_id']['color'] }}>{recurso['tipo'][0]['Tipo_de_recurso_id']['titulo']}</span>
                                            </HeaderCard>
                                            <GapCard />
                                            <TitleCard>
                                                <h2 style={{ fontWeight: '500' }}>{recurso.titulo}</h2>
                                            </TitleCard>
                                            <ServiceTitleCard>{capitalizeFirstLetter(recurso['servicio'][0]['Servicios_id']['Titulo'])}</ServiceTitleCard>
                                        </div>
                                        <div>
                                            {recurso['multimedia'] && recurso['multimedia']['id'] &&
                                                <img src={`${baseUrl}/assets/${recurso['multimedia']['id']}`} alt="s-logo" style={{ width: '100%' }} />
                                            }
                                        </div>
                                        <div>
                                            <div style={{ minHeight: '15rem' }}>
                                                <div>
                                                    <DescriptionCenter>
                                                        <HyphenSmall>
                                                            <ReactMarkdown
                                                                components={{
                                                                    // Use `CustomLink` instead of anchor element `a`
                                                                    p: selectP,
                                                                }}
                                                            >
                                                                {recurso['descripcion_markdown']}
                                                            </ReactMarkdown>
                                                            <div style={{ textAlign: 'center', alignItems: 'center', marginTop: '3rem', display: 'grid', justifyContent: 'center' }}>
                                                                <ButtonLink style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} href={recurso['enlace_boton']} target="_blank" rel="noreferrer">{recurso['texto_boton']}</ButtonLink>
                                                                {recurso['botones'].length > 0 &&
                                                                    recurso['botones'].filter((boton) => boton['item']['visible'] === true).map(boton =>
                                                                        <ButtonLink style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} href={boton['item']['enlace'] ? boton['item']['enlace'] : `https://admin.iniciativaecos.com/assets/${boton['item']['archivo']}`} target="_blank" rel="noreferrer">{boton['item']['texto_boton']}</ButtonLink>
                                                                    )
                                                                }
                                                                <ButtonLink as="button" style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'], marginBottom: '1rem' }} onClick={() => shareUrl(recurso['uuid'])}><FaShareAlt style={{ marginRight: '6px' }} /><span style={{ position: 'relative', bottom: '2px' }}>Compartir recurso</span></ButtonLink>
                                                            </div>
                                                        </HyphenSmall>
                                                    </DescriptionCenter>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            })
                        }}>
                            <div style={{ minHeight: '12rem', color: recurso['implementadora'][0]['Implementadoras_id']['color'] }} >
                                <h2>{recurso.titulo}</h2>
                            </div>
                            <ServicesPageBarA />
                            <DetailsWrapper>
                                <div>
                                    <p style={{ fontSize: 'small', marginTop: '4.5rem' }}>{capitalizeFirstLetter(recurso['servicio'][0]['Servicios_id']['Titulo'])}</p>
                                </div>
                                <div></div>
                                <IconWrapperFooter style={{ backgroundColor: recurso['implementadora'][0]['Implementadoras_id']['color'] }}>
                                    <img src={`${baseUrl}/assets/${recurso['tipo'][0]['Tipo_de_recurso_id']['icono']}`} alt="s-logo" style={{ width: '2.5rem', filter: 'invert(100%)' }} />
                                </IconWrapperFooter>
                            </DetailsWrapper>
                        </ResourcesCard>
                    )
                    : <></>}
            </ResourcesCards>
            <DisclaimerContainer>
                <DisclaimerWrapper>
                    <ServicesWhite>DISCLAIMER</ServicesWhite>
                    <ServicesPageBar />
                </DisclaimerWrapper>
                {recursos.length > 0 ?
                    <ReactMarkdown
                        components={{
                            // Use `CustomLink` instead of anchor element `a`
                            p: ServicesPB,
                            ul: ServicesUl,
                            li: ServicesLi
                        }}
                    >
                        {recursos[0]['disclaimer']}
                    </ReactMarkdown>
                    : ''}
            </DisclaimerContainer>
        </ResourcesContainer>
    );
};

export default ResourcesPage;
