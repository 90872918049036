import styled from 'styled-components';

export const ServicesH2Sub = styled.h2`
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1.5rem;
  color: #000;
  
  @media screen and (max-width: 1024px) {
    font-size: 1.15rem;
  }

  margin-bottom: 1rem;
`;

export const ServicesH2SubB = styled(ServicesH2Sub)`
  font-weight: bold;
  margin-inline: 40%;
  margin-block: auto;
  position: relative;

  @media screen and (max-width: 1024px) {
    font-size: 1.2rem;
    margin-inline: 6rem;
  }
`;

export const ServicesH2SubB1 = styled(ServicesH2SubB)`
  margin-inline: auto;
  max-width: 14rem;

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    max-width: 8rem;
  }
`;

export const ServicesH2SubA = styled(ServicesH2Sub)`
  margin: 2.5rem;
  
  @media screen and (max-width: 1024px) {
    font-size: 1.25rem;
  }

  @media screen and (max-width: 480px) {
    margin: 2rem;
    font-size: 1rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #FFF;

  @media screen and (max-width: 480px) {
    font-size: 1rem;
    margin-top: 1.5rem;

    font-weight: 400;
  }

`;

export const ServicesH2D = styled(ServicesH2)`
  font-size: 1.2rem;
  font-weight: 400;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  ul {
    margin-left: 1.2rem;
    li::marker {
      content: "- ";
      padding: 2rem;
    }
  }
`;

export const ServicesH2C = styled(ServicesH2)`
  font-weight: 400;
  margin: 2rem 5rem;
  color: #000;
  text-align: justify;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 480px) {
    margin: 2rem 2rem;
    font-size: 0.8rem;
    text-align: left;
  }
`;

export const ServicesH2A = styled(ServicesH2)`
  font-weight: bold;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export const ServicesH2A1 = styled(ServicesH2A)`
  position: relative;
  color: #000;

  @media screen and (min-width: 1480px) {
    font-size: 1rem;
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const ServicesH2B = styled(ServicesH2)`
  font-size: 1.2rem;

  @media screen and (max-width: 1024px) {
    font-size: 1.15rem;
  }

  margin-bottom: 2.5rem;
`;