import React from "react";
import { ConnectionComponent, baseUrl } from "../../utils/connection";
import { SubContainerResponsiveA } from "../atoms/containers";
import { ServiceTitle } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { PromoteBar } from "../atoms/bars";
import { ServicesH2Sub } from "../atoms/h2";
import { Listing } from "../atoms/ul";

const PromoteSection = () => {
    const data = ConnectionComponent('Ambitos_de_promocion');
    const dataAreas = ConnectionComponent('Areas');

  return (
    <SubContainerResponsiveA>
      <ServiceTitle>
        <ServicesH1>{data.Titulo}</ServicesH1>
        <PromoteBar />
        <ServicesH2Sub>
          {data.Subtitulo}
        </ServicesH2Sub>
        <Listing>
        {dataAreas.length > 1 && dataAreas.map((item, index) => (
            <li key={`area-${index}`}>
              <img src={`${baseUrl}/assets/${item.Imagen}`} alt={`area-${index}`} />
              <h2>{item.Titulo}</h2>
            </li>
          ))}
        </Listing>
      </ServiceTitle>
    </SubContainerResponsiveA>
  );
};

export default PromoteSection;
