import styled from "styled-components";

export const HeroP = styled.p`
  margin-top: 1.5rem;
  margin-left: 20%;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-align: left;
  max-width: 28rem;

  @media only screen and (max-width: 480px) {
    max-width: 20rem;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 2rem;
  margin-right: 4rem;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 24px;
  text-align: justify;
  color: #000;
`;

export const ArrowSubtitle = styled(Subtitle)`
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
`;

export const ActiveNumber = styled.p`
  font-size: 5rem;
  margin-right: 2rem;
  color: #6e2f85;
`;

export const DisabledNumber = styled(ActiveNumber)`
  font-weight: 100;
  color: #a8a8a8;
`;

export const ServicesP = styled.p`
  font-size: 1rem;
  color: #fff;
  margin: 1rem 5rem;
  text-align: left;
  hyphens: auto;

  a {
    color: #fff;
  }
`;

export const FormP = styled.p`
  font-size: 1rem;
  color: #fff;
  margin: 1rem;
  text-align: left;
  hyphens: auto;

  a {
    color: #fff;
  }
`;

export const ServicesPA = styled(ServicesP)`
  color: #bbb;
  margin: 1rem 3rem;

  a {
    color: #bbb;
  }
`;

export const ServicesPB = styled(ServicesP)`
  font-weight: 500;
  text-align: justify;

  @media screen and (max-width: 760px) {
    margin: 1rem 2rem;
    font-size: 0.8rem;
  }
`;

export const Descripciones = styled.p`
  font-size: 1rem;
  font-weight: normal;
  color: #444;
  margin: 1rem 5rem;
  text-align: justify;

  @media screen and (max-width: 868px) {
    margin: 0;
  }
`;

export const selectP = styled.p`
  font-size: 1rem;
  text-align: justify;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

