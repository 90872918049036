import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import IndexLayout from "./components/layout/IndexLayout";
import ServicesLayout from "./components/layout/ServicesLayout";
import PrinciplesLayout from "./components/layout/PrinciplesLayout";
import FormServicesLayout from "./components/layout/FormServicesLayout";
import ResourcesLayout from "./components/layout/ResourcesLayout";
import ContactLayout from "./components/layout/ContactLayout";

import ScrollToTop from "./components/ScrollToTop";

import "./App.css";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/" component={IndexLayout} exact />
        <Route path="/servicios" component={ServicesLayout} exact />
        <Route path="/principios" component={PrinciplesLayout} exact />
        <Route
          path="/solicitud_de_servicios"
          component={FormServicesLayout}
          exact
        />
        <Route path="/recursos" component={ResourcesLayout} exact />
        <Route path="/contacto" component={ContactLayout} exact />
      </Switch>
    </Router>
  );
}

export default App;
