import styled from "styled-components";

export const WrapperTitle = styled.div`
  display: block;
  align-items: center;
  padding: 0 5rem;
`;

export const WrapperTitleForm = styled.div`
  display: block;
  align-items: center;
  padding: 0;
`;

export const ServicesWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesPageWrapper = styled(ServicesWrapper)`
  grid-template-columns: 1fr 1fr;
  background-color: #eaeaea;
  padding-inline: 8rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding-inline: 1rem;
  }
`;

export const OurSection = styled(ServicesWrapper)`
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

export const PrinciplesWrap = styled(ServicesWrapper)`
  grid-template-columns: 1fr 1fr;
  background-color: #eaeaea;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const PrinciplesWrapper = styled.div`
  column-count: 2;
  column-gap: 4rem;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  margin: 10rem;

  text-align: left;

  @media screen and (max-width: 768px) {
    column-count: 1;
    margin: 4rem;
    hyphens: auto;
  }
`;

export const PrinciplesWrapperA = styled(PrinciplesWrapper)`
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;

  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  margin-inline: auto;
  margin-block: 5rem;
  padding: 0 2rem;
  width: 80vw;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-block: 2rem;
  }
`;

export const InfoWrapperA = styled(InfoWrapper)`
  margin-top: 5rem;
  margin-bottom: 0;

  @media screen and (max-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const ArrowWrap = styled.div`
  text-align: right;
  margin-right: 1rem;
  margin-top: -2rem;

  @media screen and (max-width: 860px) {
    margin-right: 1.5rem;
  }
`;

export const ServiceTitle = styled(WrapperTitle)`
  padding: 0 50px;
`;

export const ServiceTitleForm = styled(WrapperTitle)`
  padding: 0;
`;

export const FormInputsResponsive = styled.div`
  display: grid;
  gap: 0.5rem;
  font-weight: 500;
  font-size: medium;
  grid-auto-rows: 2.5rem;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  align-items: center;
  padding-bottom: 0.5rem;
`;

export const ServicesTitleA = styled(ServiceTitle)`
  margin: 2rem 5rem;

  @media screen and (max-width: 480px) {
    margin: 1rem 2rem;
  }
`;

export const PrincipleWrapperSteps = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-left: 8rem;
  margin-right: 8rem;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
    margin-left: 2rem;
    margin-right: 2rem;
    padding: 0 20px;
  }
`;

export const ResourcesWrapper = styled.div`
  margin-block: 3rem;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

export const ResourcesDescription = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);

  @media screen and (max-width: 760px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(100px, auto);
  }
`;

export const DisclaimerWrapper = styled.div`
  margin-inline: 5rem;

  @media screen and (max-width: 860px) {
    margin-inline: 2rem;
  }
`;

export const CarouselWrapper = styled.div`
  position: absolute;
  top: 35%;
  left: 15%;
  max-width: 50vw;

  @media screen and (max-width: 760px) {
    top: 40%;
  }
`;

export const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  top: 3rem;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  min-height: 8.5rem;
  div {
    border-radius: 8px;
    display: flex;
    flex-direction: column-reverse;
    gap: 0.125em;
    flex-basis: 50%;
  }
`;

export const FooterWrapper = styled.div`-
  display: flex;
  position: relative;
  margin-block: -1rem;
  padding: 2rem 0rem;
  top: 40%;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
`;

export const HeaderCard = styled.div`
  justify-content: left;
  gap: 1rem;
  margin-inline: 2rem;
  margin-top: 2rem;
  display: flex;
  align-items: center;
`;

export const GapCard = styled.div`
  justify-content: left;
  gap: 1rem;
  margin-inline: 2rem;
  display: flex;
  align-items: center;
`;

export const TitleCard = styled.div`
  text-align: left;
  margin-inline: 2rem;
  margin-block: 1.5rem;
  display: flex;
  justify-content: left;
  color: black;
`;

export const ServiceTitleCard = styled.div`
  text-align: left;
  margin-inline: 2rem;
  display: flex;
  justify-content: left;
  margin-block: 1.5rem;
  font-weight: 400;
  font-size: 0.9rem;
  color: black;
`;

export const DescriptionCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem;
`;

export const HyphenSmall = styled.div`
  text-align: justify;
  hyphens: auto;
  font-size: smaller;
`;

export const IconWrapperFooter = styled.div`
  justify-content: center;
  position: relative;
  top: 2.5rem;
  left: 2.5rem;
  border-radius: 15px 0px 15px;
  padding-inline: 1.6rem;
  padding-block: 2rem;
`;
