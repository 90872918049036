import { useState, useEffect, useCallback } from "react";
import { Directus } from '@directus/sdk';
import axios from 'axios';

 export const baseUrl = process.env.REACT_APP_BASE_URL;

export const ConnectionComponent = (component) => {
  const [data, setData] = useState({});

  const connectData = useCallback(async () => {
    const directus = new Directus(baseUrl);
    const publicData = await directus.items(component).readByQuery();
    setData(publicData.data);    
  }, [component]);

  useEffect(() => {
    connectData();
  }, [connectData]);

  return data; 
}


export const ConnectionForm = (form) => {
  const [formFields, setFormFields] = useState([]);

  const getFields = useCallback( async () => {
    try {
      const fields = await axios.get(`${baseUrl}/fields/${form}`);
      setFormFields(fields.data.data);
    } catch (error) {
      console.log(error);
    }
  }, [form]);

  useEffect(() => {
    getFields();
  },[getFields]);

  return formFields;
}
