import React from "react";
import FullPage from "../templates/FullPage";
import PrinciplesPage from "../pages/PrinciplesPage";

const PrinciplesLayout = () => {
  return (
    <FullPage page={PrinciplesPage} />
  );
};

export default PrinciplesLayout;
