import styled from 'styled-components';

export const ServicesCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  border-radius: 2rem;
  padding: 4rem;
  height: 24rem;
  margin-top: 2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  margin-inline: 15%;

  @media screen and (max-width: 480px) {
   padding: 2rem;
  }
`;

export const OurServicesCard = styled(ServicesCard)`
  padding: 2rem;
  height: 22rem;
  
  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesCardSteps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
`;

export const PrinciplesCardSteps = styled(ServicesCardSteps)`
  text-align: center;

  &:hover {
    transform: none;
  }
`;

export const PrinciplesCard = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  height: 26rem;
  margin-top: 2rem;
  transition: all 0.2s ease-in-out;
`;

export const ResourcesCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem;
  width: 90%;
  max-width: 1000px;
  margin: 4vh auto;
`;

export const ResourcesCard = styled.div`
  border-radius: 16px;
  box-shadow: 0 30px 30px -25px rgba(#4133B7, .25);
  max-width: 300px;
  background-color: white;
	padding: 2.5rem;
`;