import React from "react";

import { ConnectionComponent, baseUrl } from "../../utils/connection";
import { ArrowContainer, InfoContainer } from "../atoms/containers";
import { ArrowWrap, ImgWrap, InfoWrapper } from "../atoms/wrappers";
import { InfoRow } from "../atoms/rows";
import { Column1, Column2 } from "../atoms/columns";
import { Heading } from "../atoms/h1";
import { ServicesBar } from "../atoms/bars";
import { ArrowSubtitle, Subtitle } from "../atoms/p";
import { ArrowImg, Img } from "../atoms/img";

const MapWithArrowsSection = ({ alt }) => {
  const data = ConnectionComponent('Principios');
  const dataArrows = ConnectionComponent('Flechas');

  return (
    <>
      <InfoContainer>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <Heading>{data.Titulo}</Heading>
              <ServicesBar />
              <Subtitle>
                {data.Subtitulo}
              </Subtitle>
              <ImgWrap>
              {
                data.Imagen ?
                <Img src={`${baseUrl}/assets/${data.Imagen}`} alt={data.Imagen} /> : <></>
              }
              </ImgWrap>
            </Column1>
            <Column2>
              {dataArrows.length > 1 && dataArrows.map((item, index) => (
                <ArrowContainer key={`arrow-${index + 1}`}>
                  <ArrowWrap>
                    {
                        item.Imagen ?
                        <ArrowImg src={`${baseUrl}/assets/${item.Imagen}`} alt={`arrow-${index + 1}`} /> : <></>
                    }
                    
                  </ArrowWrap>
                  <ArrowSubtitle>{item.Texto}</ArrowSubtitle>
                </ArrowContainer>
              ))
              }
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default MapWithArrowsSection;
