import React from "react";
import FullPage from "../templates/FullPage";
import ContactPage from "../pages/ContactPage";

const ContactLayout = () => {
  return (
    <FullPage page={ContactPage} />
  );
};

export default ContactLayout;
