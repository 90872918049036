import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import NavbarFooter from "../../components/NavbarFooter";
import Sidebar from '../../components/Sidebar';

export const FullPage = (props) => {
    let Page = props.page;

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Navbar toggle={toggle} />
            <Sidebar isOpen={isOpen} toggle={toggle} />
            <Page/>
            <NavbarFooter toggle={toggle} />
        </>
    );
};

export default FullPage;
