import styled from 'styled-components';
import { NavLink as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
  background: #FFF;
  height: 86px;
  margin-top: -60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 960px) {
    transform: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;

export const NavLogo = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 1024px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-120%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #000;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkS)`
  color: #9e9ea8;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  z-index: 1;

  &.active {
    font-weight: 600;
    color: #479197;
    border-bottom: 4px solid #479197;
  }

  &:hover {
    border-bottom: 4px solid #479197;
  }
`;

export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(LinkR)`
  color: #9e9ea8;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  z-index: 4;

  &.active {
    font-weight: 600;
    color: #479197;
    border-bottom: 4px solid #479197;
  }

  &:hover {
    border-bottom: 4px solid #479197;
  }
`;

export const NavText = styled.nav`
  text-align: justify;
  padding-left: 25%;
  padding-right: 5%; 
  padding-bottom: 5rem;
  padding-top: 2rem;
  background: #212427;
  color: #FFF;

  @media screen and (max-width: 768px) {
    text-align: left;
    padding-left: 5%;
    padding-right: 5%; 
    padding-bottom: 10%;
    padding-top: 10%;
    background: #212427;
    color:#FFF;
  }
`;

export const NavLogoFooter = styled(LinkR)`
  color: #fff;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  margin-top: "4rem"; 
  margin-bottom: "auto";
`;


 