import styled from 'styled-components';

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 2rem;
  text-align: center;

  @media only screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export const ServicesH1 = styled.h1`
  font-size: 2rem;
  color: #000;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesWhite = styled(ServicesH1)`
  color: #FFF;
`;

export const ServicesWhiteA = styled(ServicesWhite)`
  text-shadow: 1px 2px 4px #313131; 
  margin-bottom: 2rem;
  margin-left: 2rem;
  text-align: left;

  @media screen and (max-width: 860px) {
    margin-bottom: 1.5rem;
    font-size: 0.8rem;
  }
`;

export const Heading = styled.h1`
  font-size: 2rem;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 24px;
  color: #000;

  @media screen and (max-width: 860px) {
    font-size: 2rem;
  }
`;