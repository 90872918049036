import { NavLink as LinkR } from 'react-router-dom';

import styled from 'styled-components';


export const ServicesButton = styled.div`
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  padding-right: 2rem;
`;

export const ServicesLink = styled(LinkR)`
  text-decoration: none;
  color: black;
  font-size: 1.2rem;

  img {
    width: 4rem; 
    position: relative; 
    top: 1.5rem
  }

  @media screen and (max-width: 480px) {
    font-size: 1rem;

    img {
      width: 4rem; 
      position: relative; 
      top: 1.5rem
    }
  }
`;

export const ServicesLinkB = styled(ServicesLink).attrs({
  as: "button"
})`
  color: white;
  background-color: transparent;
  border: none;
  font-size: 2rem;
  font-weight: bold;

  img {
    width: 6rem; 
    top: 2rem
  }
`;

export const ServicesLinkA = styled(ServicesLink)`
  font-weight: bold;

  img {
    top: 1.2rem
  }
`;

export const SendButton1 = styled.div`
  text-align: right;
  margin-right: 15rem;
  margin-bottom: -7rem;
  margin-top: 4rem;

  @media screen and (max-width: 480px) {
    margin-right: 2rem !important;
    margin-top: 4rem !important;
    margin-bottom: 3rem !important;
  }

  @media screen and (max-width: 860px) {
    margin-right: 4rem;
    margin-top: 4rem;
    margin-bottom: 0;
  }
`;

export const SendButton = styled.div`
  text-align: right;
  margin-right: 15rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 480px) {
    margin-right: 3rem;
  }
`; 

export const Arrows = styled.button`
  position: 'absolute',
  zIndex: 2,
  top: 'calc(50% - 15px)',
  backgroundColor: 'transparent',
  border: 0,
  width: 30,
  height: 30,
  cursor: 'pointer'
`;

export const LeftArrow = styled(Arrows)`
  left: 3rem;
  margin-left: 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  transform:scaleX(-1);
`;

export const RightArrow = styled(Arrows)`
  right: 3rem;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
`;

export const Arrow = styled.span`
  position: absolute; 
  bottom: 0;
  margin-left: 0px;
  width: 2rem;
  height: 2rem;
  background-size: contain; 
  top:15px;
  opacity: 0.6;
  background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
`;

export const ArrowSecond = styled(Arrow)`
  margin-left: 1rem;
`;

export const BtnCarousel = styled.a`
  transition-duration: 0.4s;
  border: transparent;
  padding: 1rem;
  border-radius: 10px 10px 10px 0px;
  width: 25vw;
  color: white;
  font-weight: 500;
  text-decoration: none;
  background-color: #50838b;
  margin-bottom: 2rem;

  :hover {
    color: black;
  }

  @media screen and (max-width: 860px) {
    font-size: 0.8rem;
    padding: 0.8rem;
  }
`;

export const SearchButton = styled.button`
  transition-duration: 0.4s; 
  border: transparent; 
  padding: 1rem;
  background-color: rgb(72, 137, 138);
  border-radius: 10px 10px 10px 0;
  width: 25vw;
  color: white; 
  font-weight: 500;  

  :hover {
    background-color: #2d5859 !important;
  }

  @media screen and (max-width: 860px) {
    margin-top: 1rem;
    width: 80vw;
  }
`;

export const ButtonLink = styled.a`
  transition-duration: 0.4s;
  border: transparent;
  padding: 1rem 4rem;
  border-radius: 10px 10px 10px 0px;
  width: 25vw;
  color: white;
  font-size: 0.8rem;
  font-weight: 500;
  text-decoration: none;

  :hover {
    color: #000 !important;
  }
`;