import styled from 'styled-components';

export const ServicesBar = styled.div`
  width: 4.5rem;  
  border-bottom: 4px solid #479197;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const PromoteBar = styled(ServicesBar)`
  margin-bottom: 2rem;
`;

export const PromoteBarA = styled(PromoteBar)`
  width: 100%;
`;

export const ServicesPageBar = styled(PromoteBar)`
  width: 6.5rem;
`;

export const ServicesPageBarA = styled(ServicesPageBar)`
  margin-top: 1.5rem;
  margin-bottom: -3rem;
`;

export const FormBar = styled(ServicesBar)`
  width: 8.5rem;
`;

export const FormBarA = styled(FormBar)`
  border-bottom: 4px solid #6e2f85;
  margin-bottom: 2rem;
  margin-left: 2.5rem;
`;
