import React from "react";
import { useHistory } from "react-router-dom";

import flecha from "../../images/FlechaBoton1.svg";
import { ConnectionComponent, baseUrl } from "../../utils/connection";
import { SubContainerC } from "../atoms/containers";
import { OurSection, ServiceTitle } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { PromoteBar } from "../atoms/bars";
import { ServicesH2A, ServicesH2Sub } from "../atoms/h2";
import { OurServicesCard } from "../atoms/cards";
import { OurServicesIcon } from "../atoms/icons";
import { ServicesButton, ServicesLink } from "../atoms/buttons";

const OurServicesSection = () => {
  let history = useHistory();

  const data = ConnectionComponent('Nuestros_Servicios');
  const dataServices = ConnectionComponent('Servicios');

  return (
    <SubContainerC>
      <ServiceTitle>
        <ServicesH1>{data.Titulo}</ServicesH1>
        <PromoteBar />
        <ServicesH2Sub>
          {data.Subtitulo}
        </ServicesH2Sub>
      </ServiceTitle>
      <OurSection>
        {dataServices.length > 1 && dataServices.map((item, index) => (
          <OurServicesCard
            key={`services${index}`}
            style={{ background: item.Color }}
            onClick={() =>
              history.push(item.URL)
            }
          >
            <OurServicesIcon>
                { item.Icono ?
                    <img src={`${baseUrl}/assets/${item.Icono}`} alt={`servicio-${index}`} /> : <></>
                }
            </OurServicesIcon>
            <ServicesH2A>{item.Titulo}</ServicesH2A>
          </OurServicesCard>
        ))
        }
      </OurSection>
      <ServicesButton>
        <ServicesLink to="/solicitud_de_servicios">
          SOLICITUD DE SERVICIOS
          <img src={flecha} alt="Enviar Solicitud" />
        </ServicesLink>
      </ServicesButton>
    </SubContainerC>
  );
};

export default OurServicesSection;
