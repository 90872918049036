import React from "react";
import { ConnectionComponent, baseUrl } from "../../utils/connection";
import { ServicesContainer } from "../atoms/containers";

const LogoSection = () => {
    const data = ConnectionComponent('Logos');

    return (
        <ServicesContainer>
            {data.length > 1 && data.map((item, index) => (
                <div key={index}>
                    <a href={item.URL} target="_blank" rel="noreferrer">
                        {item.Logo ?
                            <img src={`${baseUrl}/assets/${item.Logo}`} alt={`logo-${index}`} /> : <></>
                        }
                    </a>
                </div>
            ))
            }
        </ServicesContainer>
    );
};

export default LogoSection;
