import React from "react";
import FullPage from "../templates/FullPage";
import ResourcesPage from "../pages/ResourcesPage";

const ResourcesLayout = () => {
  return (
    <FullPage page={ResourcesPage} />
  );
};

export default ResourcesLayout;
