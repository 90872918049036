import React from "react";
import FullPage from "../templates/FullPage";
import ServicesPage from "../pages/ServicesPage";

const ServicesLayout = () => {
  return (
    <FullPage page={ServicesPage} />
  );
};

export default ServicesLayout;
