import React from "react";
import { DotElement } from "../atoms/DotElement";

export const LineDot = (props) => {
    let margin = props.margin;

    return (
        <div style={{ marginBlock: margin }}>
            <DotElement color={'#48898a'} />
            <DotElement color={'#edc565'} />
            <DotElement color={'#db874e'} />
            <DotElement color={'#872c2e'} />
            <DotElement color={'#622e6c'} />
        </div>
    );
};

export default LineDot;
