import React from "react";
import DotElement from "./DotElement";
import '../../components/ResourcesPage/css.css';

class SelectElement extends React.Component {

  constructor(props) {
    super(props);

    // @defaultSelectText => Show default text in select
    // @showOptionList => Show / Hide List options
    // @optionsList => List of options
    this.state = {
      defaultSelectText: "",
      showOptionList: false,
      optionsList: []
    };
  }

  componentDidMount() {
    // Add Event Listner to handle the click that happens outside
    // the Custom Select Container
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("keydown", this.handleKeyDown);

    this.setState({
      defaultSelectText: this.props.defaultText
    });

  }

  componentWillUnmount() {
    // Remove the event listner on component unmounting
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  // This method handles the click that happens outside the
  // select text and list area
  handleClickOutside = e => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) {
      this.setState({
        showOptionList: false
      });
    }
  };

  // This method handles the display of option list
  handleListDisplay = () => {
    this.setState(prevState => {
      return {
        showOptionList: !prevState.showOptionList
      };
    });
  };

  // This method handles the setting of name in select text area
  // and list display on selection
  handleOptionClick = e => {
    this.props.setter(`&filter${this.props.setterCondition}[_eq]=${e.target.getAttribute("data-value")}`)
    this.setState({
      defaultSelectText: e.target.getAttribute("data-name"),
      showOptionList: false
    });
    this.props.setterQuery('');
  };

  handleKeyDown = e => {
    if (this.state.showOptionList & (e.keyCode === 46 || e.keyCode === 8)) {
      this.setState({
        defaultSelectText: this.props.defaultText
      });
      this.props.setter('');
    }
  }

  render() {
    const { optionsList } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    const baseUrl = process.env.REACT_APP_BASE_URL;

    return (
      <div className="custom-select-container">
        <div
          className={showOptionList ? "selected-text active" : "selected-text"}
          onClick={this.handleListDisplay}
        >
          {defaultSelectText}
        </div>
        {showOptionList && (
          <ul className="select-options">
            {optionsList.map(option =>
              <li
                className="li-opt custom-select-option"
                data-name={option.name}
                data-value={option.value}
                key={`${option.name}-${option.id}`}
                onClick={this.handleOptionClick}
              >
                {option.color !== undefined ? <div style={{ marginInline: '1rem' }}><DotElement color={option.color} /></div> : ''}
                {option.icono !== undefined ? <i className="icon-ta" style={{ backgroundColor: '#949495', WebkitMaskImage: `url(${baseUrl}/assets/${option.icono})`, maskImage: `url(${baseUrl}/assets/${option.icono})`, marginRight: '1rem' }} /> : ''}
                {option.name}
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default SelectElement;
