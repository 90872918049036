import React from "react";

import { ConnectionComponent, baseUrl } from "../../utils/connection";
import { PrinciplesContainer } from "../atoms/containers";
import { PrinciplesWrapper } from "../atoms/wrappers";

const MessageSection = () => {
    const data = ConnectionComponent('Texto_en_columnas');

    return (
        <PrinciplesContainer style={data.Fondo_de_pantalla ? { backgroundImage: `url(${baseUrl}/assets/${data.Fondo_de_pantalla})` } : {}}>
            <PrinciplesWrapper>
                {data.Texto}
            </PrinciplesWrapper>
        </PrinciplesContainer>
    );
};

export default MessageSection;
