import styled from "styled-components";

export const FormWrap = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;

  @media screen and(max-width: 400px) {
    height: 80%;
  }
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  background-color: transparent;
  outline: none;
  font-stretch: semi-condensed;
  font-size: 1.2rem;
  border-width: 0;
  color: white;
  margin-bottom: 32px;
  border-radius: 4px;

  :focus {
    border-width: 0 0 1px;
    border-color: white;
    font-stretch: semi-condensed;
    font-size: 1.2rem;
  }

  ::placeholder {
    color: #a282ae;
  }

  @media screen and (max-width: 1024px) {
    padding: 0;
    font-size: 1rem;

    :focus {
      border-width: 0 0 1px;
      border-color: white;
      font-stretch: semi-condensed;
      font-size: 1rem;
    }

    ::placeholder {
      color: #a282ae;
    }
  }
`;

export const Checker = styled.div`
  .styl {
    display: block;
    position: relative;
    padding-left: 2rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 400;
    color: #000;
  }
  /* Hide the checkbox which is default*/
  input[type="checkbox"] {
    visibility: hidden;
  }
  input[type="radio"] {
    visibility: hidden;
  }

  /* now create a custom checkbox based
  on requirement */
  .chkmrk {
    position: absolute;
    left: 0;
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border-style: solid;
    border-color: #479197;
  }
  /* mention the background color to be
  shown when it is checked */
  .styl input:checked ~ .chkmrk {
    background-color: #479197;
  }
  /* do not show when the checkbox is not checked */
  .chkmrk:after {
    content: "";
    position: absolute;
    display: none;
  }
  /* showthe checkmark when it is checked */
  .styl input:checked ~ .chkmrk:after {
    display: block;
  }
`;

export const FormInputLarge = styled.input`
  background-color: transparent;
  outline: none;
  font-stretch: semi-condensed;
  font-size: 1.2rem;
  color: black;
  border-radius: 0.5rem;
  border-color: black;
  border-width: 0px 0px 1px;
  margin-block: 1rem;
  font-weight: 600;

  :focus {
    border-width: 0 0 1px;
    border-color: black;
    font-stretch: semi-condensed;
    font-size: 1.2rem;
  }

  ::placeholder {
    color: #000;
  }

  @media screen and (max-width: 1024px) {
    font-size: 1rem;

    :focus {
      border-width: 0 0 1px;
      border-color: black;
      font-stretch: semi-condensed;
      font-size: 1rem;
    }

    ::placeholder {
      color: #000;
    }
  }
`;

export const FormArea = styled(FormInputLarge).attrs({
  as: "textarea",
})`
  width: 100%;

  :focus {
    border-radius: 0.5rem;
    border-color: black;
    border-width: 0px 0px 1px;
  }
`;

export const SearchInput = styled.input`
  border: transparent;
  padding: 1rem;
  width: 50vw;
  border-radius: 10px 10px 10px 0;
  font-weight: 500;

  @media screen and (max-width: 860px) {
    width: 80vw;
  }
`;
