import styled from 'styled-components';

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
  text-align: center;
`;


export const Column1A = styled(Column1)`
    padding: 2rem 6rem;
    display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    padding: 2rem 2rem;
  }
`;
