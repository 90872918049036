import React from "react";

import { ConnectionComponent, baseUrl } from "../../utils/connection";

import { SubContainerE } from "../atoms/containers";
import { ServicesWrapper, WrapperTitle } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { ServicesBar } from "../atoms/bars";
import { ServicesH2, ServicesH2Sub } from "../atoms/h2";
import { ServicesCard } from "../atoms/cards";
import { ServicesIcon } from "../atoms/icons";

const ServicesSection = () => {
    const mission = ConnectionComponent('Mision');
    const missionCards = ConnectionComponent('Tarjetas_de_misiones');

    return (
        <SubContainerE>
            <WrapperTitle>
                <ServicesH1>{mission.Titulo}</ServicesH1>
                <ServicesBar />
                <ServicesH2Sub>
                    {mission.Subtitulo}
                </ServicesH2Sub>
            </WrapperTitle>
            <ServicesWrapper>
                {missionCards.length > 1 && missionCards.map((item, index) => (
                    <ServicesCard style={{ background: item.Color }} key={`mission${index}`}>
                        <ServicesIcon>
                            <img src={`${baseUrl}/assets/${item.Icono}`} alt={item.Icono} />
                        </ServicesIcon>
                        <ServicesH2>
                            {item.Titulo}
                        </ServicesH2>
                    </ServicesCard>
                ))}
            </ServicesWrapper>
        </SubContainerE>
    );
};

export default ServicesSection;
