import React from "react";
import styled from 'styled-components';

const Dot = styled.span`
    height: 0.8rem;
    width: 0.8rem;
    margin-inline: 0.3rem;
    border-radius: 50%;
    display: inline-block;
  `;

export const DotElement = (props) => {
  let color = props.color;

  return (
    <Dot style={{backgroundColor: color}}/>
  );
};

export default DotElement;
