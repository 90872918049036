import React from "react";
import FullPage from "../templates/FullPage";
import FormServices from "../pages/FormServicesPage";

const FormServicesLayout = () => {
  return (
    <FullPage page={FormServices} />
  );
};

export default FormServicesLayout;
