import styled from "styled-components";

/*
    ContactFormSection
    ContactPage
    FormInscriptionPage
*/

// ServicesContainer
export const Container = styled.div`
  display: list-item;
  background: #6e2f85;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #6e2f85;
`;

export const ContainerForm = styled.div`
  width: 55%;
  display: block;
  @media screen and (max-width: 567px) {
    width: 85%;
  }
`;

/*
 */
// ServicesContainerB
export const SubContainer = styled(Container)`
  display: block;
  background: #fff;
  margin-top: 5rem;
  margin-bottom: 2rem;

  padding-block: 0;
`;

/*
    EventPage
    LibraryPage
    PrinciplesPage
    ServicesPage
*/
// ServicesContainerB
export const SubContainerA = styled(SubContainer)`
  margin-bottom: 5rem;
`;

// FormServicesPage
//ServicesContainer
export const FormContainer = styled.form`
  display: block;
  background: #fff;
  padding-bottom: 5rem;
  background-color: #fff;
`;
//ServicesContainerB
export const SubContainerB = styled(SubContainer)`
  margin-bottom: 1rem;
`;

// LogoSection

export const ServicesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #dadada;
  align-items: center;

  div {
    width: 25%;
    padding: 3rem;
    box-sizing: border-box;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 480px) {
    div {
      width: 100%;
      padding: 3rem;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }
  }
`;

// OurServicesSection
//ServicesContainer
export const SubContainerC = styled(SubContainer)`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

// PromoteSection
//ServicesContainer
export const SubContainerResponsiveA = styled(SubContainerA)`
  padding-top: 0;

  @media screen and (max-width: 488px) {
    margin-top: 0;
  }
`;

// Resources
//ServicesContainer
export const SubContainerD = styled(SubContainer)`
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const ResourcesContainer = styled.div`
  background-color: #f2f2f2;
  padding-bottom: 8rem;
`;

// ServicesSection
//ServicesContainer
export const SubContainerE = styled(SubContainer)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;
// Footer
export const FooterContainer = styled.div`
  background-color: #101522;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;
//Hero
export const HeroContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  align-items: center;
  justify-content: left;
  height: 30em;
  position: relative;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 100%;
  position: absolute;
  padding: 0 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 480px) {
    padding: 0;
  }
`;

//InfoSection
export const InfoContainer = styled.div`
  color: #fff;
  background: #fff;

  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

export const ArrowContainer = styled.div`
  display: inline-flex;
`;

//Navbar
export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;

// Rosurces
// .custom-select-container
export const SelectContainer = styled.div`
  display: inline-block;
  min-width: 25vw;
  max-width: 25vw;
  text-align: center;

  @media screen and (max-width: 860px) {
    min-width: 80vw;
    max-width: 80vw;
    margin-bottom: 1rem;
  }
`;
//.container
export const PrincipalContainer = styled.div`
  position: relative;
  text-align: center;
  color: white;
`;

//sidebar
export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: #0d0d0d;
  display: grid;
  text-align: center;
  top: -5px;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};
`;

// TwoColumnMessageSection

export const PrinciplesContainer = styled.div`
  display: flex;
  position: relative;
  background-position: center center;
  background-size: cover;
  margin-top: 5rem;
`;

//
export const SelectGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1rem;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

export const DisclaimerContainer = styled.div`
  margin-bottom: -5rem;
  background: #6e2f85;
  padding: 5rem;

  @media screen and (max-width: 860px) {
    margin-inline: 0rem;
    padding: 2rem;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const DividerDiv = styled(FlexCenter)`
  margin-top: 3rem;
`;

export const SearchContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 1rem;

  @media screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`;

export const CustomSelect = styled.div`
  display: inline-block;
  min-width: 25vw;
  max-width: 25vw;
  text-align: center;
`;
