import React from "react";
import FullPage from "../templates/FullPage";
import IndexPage from "../../components/pages/IndexPage";

const IndexLayout2 = () => {
  return (
    <FullPage page={IndexPage} />
  );
};

export default IndexLayout2;
