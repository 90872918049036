import styled from 'styled-components';

export const Listing = styled.ul`
  padding-top: 1rem;
  list-style: none;
  text-align: center;
  display: grid;
  column-gap: 5rem;
  row-gap: 1rem;
  margin-right: 15rem;
  margin-left: 15rem;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: 1024px) {
    margin: 0;
  }

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }

  li {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    
    img {
      max-width: 12rem;
      text-align: center;
    }

    h2 {
      font-size: 1rem;
      align: center;
      max-width: 12rem;
    }
  }
`;

export const ServicesOl = styled.ol`
  font-size: 1rem;
  font-weight: normal;
  color: #000;

  li {
    margin-left: 8rem;
    padding: 1rem;
    ol {
      li {
        margin-left: 2rem;
      }
      li::marker {

        content: "●";
        color: black;
      }
    }
  }

  li::marker {
    content: "▶";
  
    color: #f2c84c;
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    li {
      margin-left: 1rem;
      padding: 1rem;
      ol {
        li {
          margin-left: 0.5rem;
        }
        li::marker {
  
          content: "●";
          color: black;
        }
      }
    }
  }
} 
`;

export const ServicesOl2 = styled.ol`
  font-size: 1.6rem;
  font-weight: normal;
  color: #000;

  li {
    margin-left: 8rem;
    padding: 2rem;
    ol {
      li {
        margin-left: 2rem;
      }
      li::marker {

        content: "●";
        color: black;
      }
    }
  }

  li::marker {
    content: "●";
  }

  @media screen and (max-width: 480px) {
    font-size: 0.8rem;
    li {
      margin-left: 1rem;
      padding: 1rem;
      ol {
        li {
          margin-left: 0.5rem;
        }
        li::marker {
  
          content: "●";
          color: black;
        }
      }
    }
  }
}`;

export const ServicesUl = styled.ul`
  list-style: none;
`;

export const ServicesLi = styled.li`
  font-size: 1rem;
  font-weight: 500;
  color: #FFF;
  margin: 1rem 6rem;
  text-align: justify;

  ::before {
    content: "-";
    color: #48898a;
    font-weight: bold;
    display: inline-block; 
    width: 1em;
    margin-left: -1em;
  }

  @media screen and (max-width: 768px) {
    margin: 1rem 2rem;
    font-size: 0.8rem;
  }
`;