import styled from 'styled-components';

export const ServicesIcon = styled.div`
  margin-bottom: 0.2rem;

  border-radius: 50%;
  background-color: #FFF;
  border: 6px solid #479197;
  box-shadow: 0 0 0 3px #f2c84c;
  text-align: center;
  img {
    padding: 1rem
  }
`;

export const OurServicesIcon = styled.div`
  text-align: center;
  img {
    width: 8rem;
  }
`;

export const IconCard  = styled.i`
  width: 2.5rem;
  height: 2.5rem;
`;