import React, { useState } from "react";

import axios from "axios";
import Swal from 'sweetalert2';
import flecha from "../../images/FlechaBoton1.svg";
import withReactContent from 'sweetalert2-react-content';
import { ConnectionComponent, ConnectionForm } from '../../utils/connection.js';
import { Container } from "../atoms/containers";
import { ServiceTitle } from "../atoms/wrappers";
import { ServicesH2B } from "../atoms/h2";
import { FormArea, FormInput, FormWrap } from "../atoms/forms";
import { InfoRow } from "../atoms/rows";
import { Column1A, Column2 } from "../atoms/columns";
import { ServicesWhite } from "../atoms/h1";

const ContactFormSection = () => {
  const MySwal = withReactContent(Swal)

  const [inputs, setInputs] = useState({});

  const data = ConnectionComponent('Pagina_de_contacto');
  const formFields = ConnectionForm('Respuestas_de_la_pagina_de_contacto');

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs(values => ({ ...values, [name]: value }))
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post("https://admin.iniciativaecos.com/items/Respuestas_de_la_pagina_de_contacto", inputs)
      .then((response) => {
        if (response) {
          MySwal.fire({
            icon: 'success',
            title: data['Popup_titulo_exitoso'],
            text: data['Popup_texto_exitoso']
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: data['Popup_titulo_error'],
            text: data['Popup_texto_error']
          })
        }
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: data['Popup_titulo_error'],
          text: data['Popup_texto_error']
        })
      });
  }

  const renderForm = (item, index) => {
    let input;
    if (item.meta) {
      switch (item.meta.interface) {
        case "input":
          input = <FormInput
            key={`input${index}`}
            name={item.field}
            type={item.type}
            required={item.meta.required}
            placeholder={`${item.meta.translations[0].translation}:`}
            onChange={handleChange}
          />;
          break;
        case "input-multiline":
          input = <FormArea
            key={`input${index}`}
            name={item.field}
            type={item.type}
            required={item.meta.required}
            placeholder={`${item.meta.translations[0].translation}:`}
            onChange={handleChange} />;
          break;
        default:
          input = "";
          break;
      }
    }
    return input;
  }

  return (
    <Container>
        <ServiceTitle>
          <ServicesWhite>{data ? data['Titulo'] : ''}</ServicesWhite>
          <ServicesH2B>
            {data ? data['Subtitulo'] : ''}
          </ServicesH2B>
        </ServiceTitle>

        <FormWrap onSubmit={handleSubmit}>
          <InfoRow>
            <Column1A>
              {formFields.map((item, index) => {
                return renderForm(item, index);
              })}
            </Column1A>
            <Column2>
              <button
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "transparent",
                  border: "none",
                }}
              >
                <img
                  src={flecha}
                  alt="Enviar Solicitud"
                  style={{ width: "4rem", position: "relative", top: "1.4rem" }}
                />
                <span
                  style={{
                    color: "#f2c84c",
                    fontSize: "2rem",
                    fontWeight: "bold",
                    marginLeft: "2rem",
                  }}
                >
                  Enviar
                </span>
              </button>
            </Column2>
          </InfoRow>
        </FormWrap>
      </Container>
  );
};

export default ContactFormSection;
