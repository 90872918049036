import React, { useState, useEffect, useCallback } from "react";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogoFooter,
  NavMenu,
  NavItem,
  NavBtnLink,
  NavText,
} from "../Navbar/NavbarElements";

import { Directus } from '@directus/sdk';

const NavbarFooter = ({ toggle }) => {
  const baseUrl = 'https://admin.iniciativaecos.com';
  const [data, setData] = useState({});

  const connectData = useCallback(async () => {
    const directus = new Directus(baseUrl);
    const publicData = await directus.items('Pie_de_pagina').readByQuery();
    setData(publicData.data);
  }, []);

  useEffect(() => {
    connectData();
  }, [connectData]);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav style={{ background: "#212427" }}>
          <NavbarContainer>
            <NavLogoFooter to="/">
              <img src={data.Logo ? `${baseUrl}/assets/${data.Logo}` : ''} alt="logo" style={{ width: '10rem', paddingTop: '2rem'}}/>
            </NavLogoFooter>
            <NavMenu>
              <NavItem>
                <NavBtnLink to="/" exact={true}>
                  QUIÉNES SOMOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/servicios" exact={true}>
                  SERVICIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/principios" exact={true}>
                  PRINCIPIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/solicitud_de_servicios" exact={true}>
                  SOLICITUD DE SERVICIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/recursos" exact={true}>
                  RECURSOS
                </NavBtnLink>
              </NavItem>
              {/* <NavItem>
                <NavBtnLink to="/biblioteca" exact={true}>
                  BIBLIOTECA
                </NavBtnLink>
              </NavItem> */}
              <NavItem>
                <NavBtnLink to="/contacto" exact={true}>
                  CONTACTO
                </NavBtnLink>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
        <NavText>
          {data.Mensaje}
        </NavText>
      </IconContext.Provider>
    </>
  );
};

export default NavbarFooter;
