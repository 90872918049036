import React from "react";

import HeroSection from "../organisms/HeroSection";
import ServicesSection from "../organisms/ServicesSection";
import MessageSection from "../organisms/MessageSection";
import MapWithArrowsSection from "../organisms/MapWithArrowsSection";
import PromoteSection from "../organisms/PromoteSection";
import OurServicesSection from "../organisms/OurServicesSection";
import LogoSection from "../organisms/LogoSection";
import ContactFormSection from "../organisms/ContactFormSection";

export const IndexPage = () => {
  return (
    <>
      <HeroSection />
      <ServicesSection />
      <MessageSection />
      <MapWithArrowsSection />
      <PromoteSection />
      <OurServicesSection />
      <LogoSection />
      <ContactFormSection />
    </>
  );
};

export default IndexPage;
