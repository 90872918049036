import React from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavBtnLink,
} from "./NavbarElements";

import logo from "../../images/logo-ecos.svg";

const Navbar = ({ toggle }) => {
  return (
    <>
      <IconContext.Provider value={{ color: "#000" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              <img src={logo} alt="logo" style={{ width: '10rem' }} />
            </NavLogo>

            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>

            <NavMenu>
              <NavItem>
                <NavBtnLink to="/" exact={true}>
                  QUIÉNES SOMOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/servicios" exact={true}>
                  SERVICIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/principios" exact={true}>
                  PRINCIPIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/solicitud_de_servicios" exact={true}>
                  SOLICITUD DE SERVICIOS
                </NavBtnLink>
              </NavItem>
              <NavItem>
                <NavBtnLink to="/recursos" exact={true}>
                  RECURSOS
                </NavBtnLink>
              </NavItem>
              {/* <NavItem>
                <NavBtnLink to="/biblioteca" exact={true}>
                  BIBLIOTECA
                </NavBtnLink>
              </NavItem> */}
              <NavItem>
                <NavBtnLink to="/contacto" exact={true}>
                  CONTACTO
                </NavBtnLink>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
