import React from "react";
import step1 from "../../images/square-1.svg";
import step2 from "../../images/square-2.svg";
import step3 from "../../images/square-3.svg";
import step4 from "../../images/square-4.svg";

import Icon1 from "../../images/quie-step-1.svg";
import Icon2 from "../../images/quie-step-2.svg";
import Icon3 from "../../images/quie-step-3.svg";
import Icon4 from "../../images/quie-step-4.svg";
import { SubContainerA } from "../atoms/containers";
import { PrincipleWrapperSteps, PrinciplesWrap, ServiceTitle } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { PromoteBar } from "../atoms/bars";
import { ServicesH2SubA, ServicesH2SubB, ServicesH2SubB1 } from "../atoms/h2";
import { PrinciplesCard, PrinciplesCardSteps } from "../atoms/cards";
import { Logos } from "../atoms/img";

const PrinciplesPage = () => {
  return (
    <SubContainerA>
      <ServiceTitle>
        <ServicesH1>¿A QUIÉNES BRINDAMOS NUESTROS SERVICIOS?</ServicesH1>
        <PromoteBar />
        <ServicesH2SubA>
          ECOS prestará servicios a organizaciones que tengan las siguientes
          características:
        </ServicesH2SubA>
      </ServiceTitle>
      <PrinciplesWrap>
        <PrinciplesCard style={{ backgroundImage: `url(${step1})` }}>
          <ServicesH2SubB>
            Que promuevan discursos o prácticas de los derechos humanos.
          </ServicesH2SubB>
        </PrinciplesCard>
        <PrinciplesCard style={{ backgroundImage: `url(${step2})` }}>
          <ServicesH2SubB>Que sean apartidistas y sin fines de lucro</ServicesH2SubB>
        </PrinciplesCard>
        <PrinciplesCard style={{ backgroundImage: `url(${step3})` }}>
          <ServicesH2SubB>Que se encuentren en México o Centroamérica</ServicesH2SubB>
        </PrinciplesCard>
        <PrinciplesCard style={{ backgroundImage: `url(${step4})` }}>
          <ServicesH2SubB>
            Que no se encuentren en situaciones de
            corrupción, discriminación o violencia.
          </ServicesH2SubB>
        </PrinciplesCard>
      </PrinciplesWrap>
      <ServiceTitle>
        <ServicesH2SubA>
          Para la implementación de la iniciativa consideramos cuatro principios
          de inclusión y equidad:
        </ServicesH2SubA>
      </ServiceTitle>
      <PrincipleWrapperSteps>
        <PrinciplesCardSteps>
          <Logos src={Icon1} alt="no_1" />
          <ServicesH2SubB1>RACIAL</ServicesH2SubB1>
        </PrinciplesCardSteps>
        <PrinciplesCardSteps>
          <Logos src={Icon2} alt="no_2" />
          <ServicesH2SubB1>DE GÉNERO</ServicesH2SubB1>
        </PrinciplesCardSteps>
        <PrinciplesCardSteps>
          <Logos src={Icon3} alt="no_3" />
          <ServicesH2SubB1>INTERCULTURAL</ServicesH2SubB1>
        </PrinciplesCardSteps>
        <PrinciplesCardSteps>
          <Logos src={Icon4} alt="no_4" />
          <ServicesH2SubB1>PARA PERSONAS CON DISCAPACIDAD</ServicesH2SubB1>
        </PrinciplesCardSteps>
      </PrincipleWrapperSteps>
      <ServiceTitle>
        <ServicesH2SubA style={{ marginBottom: "10rem" }}>
          En ECOS promovemos procesos de aprendizaje colaborativo; dichos
          procesos están cargados de retos ante los cuales nos reconocemos
          falibles. Por ello, agradecemos la comprensión y damos la bienvenida a
          la retroalimentación que nos ayude a mejorar nuestros servicios.
        </ServicesH2SubA>
      </ServiceTitle>
    </SubContainerA>
  );
};

export default PrinciplesPage;
