import React from "react";

import { ConnectionComponent, baseUrl } from '../../utils/connection';

import { HeroContainer, HeroContent } from "../atoms/containers";
import BackgroundImage from "../atoms/backgroundImage";
import { HeroH1 } from "../atoms/h1";
import { HeroP } from "../atoms/p";

const HeroSection = () => {
    const hero = ConnectionComponent('Hero');

    return hero.length > 0 ?
        <HeroContainer>
            <BackgroundImage>
                { hero[0].Fondo_Pantalla &&
                    <img src={`${baseUrl}/assets/${hero[0].Fondo_Pantalla}`} alt="background-hero" />
                }
            </BackgroundImage>
            <HeroContent
            >
                <HeroH1
                    color="#FFF"
                    fontSize="2rem"
                    textAlign="center"
                >{hero.length > 0 ? hero[0].Titulo : ""}</HeroH1>
                <HeroP>
                    {hero.length > 0 ? hero[0].Subtitulo : ""}
                </HeroP>
            </HeroContent>
        </HeroContainer>
        : <div></div>
};

export default HeroSection;
