import React from "react";

import { ConnectionComponent } from "../../utils/connection";

import { SubContainer } from "../atoms/containers";
import { ServiceTitle } from "../atoms/wrappers";
import { ServicesH1 } from "../atoms/h1";
import { PromoteBar } from "../atoms/bars";
import { ServicesPA } from "../atoms/p";

import ContactFormSection from "../organisms/ContactFormSection";

import ReactMarkdown from 'react-markdown';

const ContactPage = () => {
  const data = ConnectionComponent('Pagina_de_contacto');
  const markdown = data ? data['Texto_de_privacidad'] : '';

  return (
    <>
      <SubContainer >
        <ServiceTitle>
          <ServicesH1>CONTACTO</ServicesH1>
          <PromoteBar />
        </ServiceTitle>
      </SubContainer>
      <ContactFormSection />
      <div style={{ paddingBottom: "6rem", paddingTop: "0.1rem", background: "#6e2f85" }}>
          <ReactMarkdown
            components={{
              // Use `CustomLink` instead of anchor element `a`
              p: ServicesPA,
            }}
          >
            {markdown}
          </ReactMarkdown>
      </div>
    </>
  );
};

export default ContactPage;
